import { AppProps } from 'next/app'
import React, { Fragment } from 'react'
import '@/styles/global.scss'
import Head from 'next/head'

export default function MyApp({ Component, pageProps }: AppProps) {
  return (
    <Fragment>
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          <meta name="referrer" content="no-referrer"></meta>
      </Head>
      <Component {...pageProps} />
    </Fragment>
  )
}
